export const productOptions = [
    {
        label: '潜盾(SkyShield)',
        value: 0,
    },
    {
        label: '潜行者(SkyCruiser)',
        value: 1,
    },
    {
        label: '前哨(SkyGuard)',
        value: 2,
    },
    {
        label: '鹰眼(SkyEyes)',
        value: 3,
    },
    {
        label: '定制(Custom)',
        value: 4,
    },
    {
        label: '未知无人机(UnknownDrone)',
        value: 5,
    },
    {
        label: '微型检测器(MiniDT)',
        value: 6,
    },
];


export const powerOptions = [
    {
        label: '20W',
        value: 0,
    },
    {
        label: '50W',
        value: 1,
    },
    {
        label: '100W',
        value: 2,
    },
    {
        label: '0W',
        value: 3,
    }
];
export const directionOptions = [
    {
        label: '全向',
        value: 0,
    },
    {
        label: '2扇面定向',
        value: 1,
    },
    {
        label: '3扇面定向',
        value: 2,
    },
    {
        label: '4扇面定向',
        value: 3,
    },
    {
        label: '5扇面定向',
        value: 4,
    },
    {
        label: '6扇面定向',
        value: 5,
    }
];

export const LNAOptions = [
    {
        label: '无',
        value: 0,
    },
    {
        label: '45db',
        value: 7,
    },
    {
        label: '15db',
        value: 8,
    },
    {
        label: 'FB_20db',
        value: 9,
    },
    {
        label: '30db',
        value: 10,
    }
];


export const carrierPlateTypesOptions = [
    {
        label: 'DT板2.0',
        value: 6,
    },
    {
        label: 'DS板4.0',
        value: 7,
    },
    {
        label: 'DT板mini',
        value: 8,
    }
];

export const distanceOptions = [
    {
        label: '500米',
        value: 1,
    },
    {
        label: '1公里',
        value: 2,
    },
    {
        label: '3公里',
        value: 3,
    },
    {
        label: '5公里',
        value: 5,
    },
    {
        label: '8公里',
        value: 4,
    },
    {
        label: '10公里',
        value: 6,
    }
];
