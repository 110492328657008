import { Route,Routes } from 'react-router-dom'
import AuthPage from './Auth/authDD';
import Home from './Home/Home';



function Router() {
    return (
      <Routes>
        <Route index path="/" element={<AuthPage />} />
        <Route path="/home" element={<Home />} />
        {/* <Route path="/users/:id" element={<User />} /> */}
      </Routes>
    );
  }
 
export default Router
