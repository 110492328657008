import React, {useEffect} from 'react';
import { Layout, Menu, Tabs  } from 'antd';
import './home.css'
import { useState } from 'react';
import  QueryModal   from  '../components/QueryModal/QueryModal'
import GenerateEncoding from '../components/GenerateEncoding/GenerateEncoding';

const { Header } = Layout;

const Home = () => {
    const [menuSelect, setMenuSelect] = useState("1")
    const headerItems = [
        {
            label : <h3>历正产品型号查询工具</h3>,
            key : "1",
        },
        {
            label : <h3>设备编码生成器</h3>,
            key : "2",
        },
    ]
    const onChange = (key) => {
        setMenuSelect(key);
      };

    return (
        <Layout>
            <Header
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    color: "white"
                }}
                className='non-printable'
            >
                <Tabs defaultActiveKey="1" items={headerItems} onChange={onChange}  type="card"/>
                
            </Header>
            {
              menuSelect === '1' && <QueryModal/>
            }
            {
             menuSelect !== '1' && <GenerateEncoding/>
            }
        </Layout>
    );
};
export default Home;
