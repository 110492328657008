import React, { useEffect, memo } from 'react';
import { Layout, Row, Col, Button, Radio, Input, Card, Modal, InputNumber  } from 'antd';
import { Select, Space } from 'antd';
import './GenerateEncoding.css'
import { powerOptions, LNAOptions, carrierPlateTypesOptions, distanceOptions, productOptions, directionOptions } from './utils';
import { useState } from 'react';
import Axios from 'axios';
import { getApiUrlAnother } from '../..//Httputil/httpsutil';

const { Content, Footer } = Layout;
const GenerateEncoding = () => {
    const [snNewSequence,setSnNewSequence] = useState('')
    const [another,setAnother]=useState('')
    const [param, userParam] = useState({ pdt_series: 0, pa_type: [[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0]], lna_module: 0, base_board: 7, distance: 4, today_series: 0  })
    const [presentationInformation, setPresentationInformation] = useState({pdt_series:'潜盾(SkyShield)',pa_type_433:'',pa_type_900:'' ,pa_type_1200:'',pa_type_1400:'',pa_type_1500:'',pa_type_1800:'',pa_type_2400:'',pa_type_5800:'',rf_module:'无',base_board:'DS板4.0(DS40)',range:'8公里(8000)',pdt_date:'',today_series:snNewSequence})
    const [showInformation,setShowInformation] = useState(true)
    const [snCode,setSnCode] = useState('')
    const [imgSnCode,setImgSnCode] = useState('')
    const [codeImg,setCodeImg] = useState('')
    const [nowTime,setNowTime] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImgModalOpen, setIsImgModalOpen] = useState(false);
    const [isUsedModalOpen, setIsUseModalOpen] = useState(false);
    const [isNumberModalOpen, setIsNumberModalOpen] = useState(false);
    const [isMaxModalOpen, setIsMaxModalOpen] = useState(false);
    const [extendInformation,setExtendInformation] = useState('')
    const [showSnImg,setShowSnImg] = useState(false)
    const [lastShowInformation,setLastShowInformation] = useState('')
    const [inputDisabled,setInputDisabled] = useState(true)

    const powerChangeSelect = (checkedValues, type) => {
            let newData = { ...param}
            // eslint-disable-next-line default-case
            switch (type) {
                case 'pdt_series':
                    newData.pdt_series = checkedValues
                    break
                case '433L':
                    newData.pa_type[0][0] = checkedValues
                    break
                case '433R':
                    newData.pa_type[0][1] = checkedValues
                    break
                case '900L':
                    newData.pa_type[1][0] = checkedValues
                    break
                case '900R':
                    newData.pa_type[1][1] = checkedValues
                    break
                case '1.2L':
                    newData.pa_type[7][0] = checkedValues
                    break
                case '1.2R':
                    newData.pa_type[7][1] = checkedValues
                    break
                case '1.4L':
                    newData.pa_type[2][0] = checkedValues
                    break
                case '1.4R':
                    newData.pa_type[2][1] = checkedValues
                    break
                case '1.5L':
                    newData.pa_type[3][0] = checkedValues
                    break
                case '1.5R':
                    newData.pa_type[3][1] = checkedValues
                    break
                case '1.8L':
                    newData.pa_type[4][0] = checkedValues
                    break
                case '1.8R':
                    newData.pa_type[4][1] = checkedValues
                    break
                case '2.4L':
                    newData.pa_type[5][0] = checkedValues
                    break
                case '2.4R':
                    newData.pa_type[5][1] = checkedValues
                    break
                case '5.8L':
                    newData.pa_type[6][0] = checkedValues
                    break
                case '5.8R':
                    newData.pa_type[6][1] = checkedValues
                    break
                case 'lna_module':
                    newData.lna_module = checkedValues
                    break
                case 'base_board':
                    newData.base_board = checkedValues
                    break
                case 'distance':
                    newData.distance = checkedValues
                    break
            }
            getNewSN(newData)
            userParam(newData)
        }

    const getNewSN = (value) => {
        //这里根据数据得到最新的序号，然后传进去最新的信息，得到最新的sn码
        Axios.post(getApiUrlAnother() + "/getsn", value).then(async(res) => {
            let newSn = await getNowSnSequence(res.data.sn);
            let newData = { ...value }
            newData.today_series = newSn
            userParam(newData)
            return newData
        }).then(data => {
            Axios.post(getApiUrlAnother() + "/getsn", data).then((res) => {
                setSnCode(res.data.sn)
            }).catch(err=>{
                console.log(err)
            })
        })
        .catch(err => {
            console.log(err)
        })

    }
    //重置接口
    const changeTobeDefault = () => {
        Axios.post(getApiUrlAnother() + "/getsn", { pdt_series: 0, pa_type: [[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0]], lna_module: 0, base_board: 7, distance: 4, today_series: 0  }).then(res => {
            Axios.post(getApiUrlAnother() + "/getnum",{sn:res.data.sn}).then(rep => {
                if(res.status === 200){
                    setExtendInformation('')
                   Axios.post(getApiUrlAnother() + "/getsn", { pdt_series: 0, pa_type: [[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0]], lna_module: 0, base_board: 7, distance: 4, today_series: rep.data.series_num }).then((res)=>{
                       setSnCode(res.data.sn)
                   }).catch(err => {
                    console.log(err)
                })
                   setSnNewSequence(rep.data.series_num)
                   setAnother(rep.data.series_num)
                   userParam({pdt_series: 0, pa_type: [[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0],[-1,0]], lna_module: 0, base_board: 7, distance: 4, today_series: rep.data.series_num })
                }
            })
        }).catch(err => {
            console.log(err)
        }).catch(err => {
            console.log(err)
        })
    }
    
    const viewDetails = () => {
        setIsModalOpen(true)
        getSNInformation()
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };
    
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleImgOk = () => {
        setIsImgModalOpen(false);
    };
    const handleNumberOk = () => {
        setIsNumberModalOpen(false);
    };
    const handleMaxOk = () => {
        setIsMaxModalOpen(false);
    };
    const handleUsedOk = () => {
        setIsUseModalOpen(false);
    };
    
    const handleImgCancel = () => {
        setIsImgModalOpen(false);
        setIsUseModalOpen(false);
        setIsMaxModalOpen(false);
        setIsImgModalOpen(false);
        setIsNumberModalOpen(false);
    };

    //生成
    const generateCode =async  () =>{
        //根据最新的sn码得到此sn码的序号
        setInputDisabled(true)
        Axios.post(getApiUrlAnother() + "/barcode/produce",{sn:snCode, note: extendInformation}, { responseType: 'arraybuffer' }).then(res => {
            const blob = new Blob([res.data], { type: 'image/png' });
            // 创建URL对象，用于在img标签中显示
            const imageUrl = URL.createObjectURL(blob);
            // 设置图片URL
            const needData = res.headers['content-type'].split('/')
            if(needData[1] === '0'){
                if(needData[0] === '0'){
                    setCodeImg(imageUrl);
                    setImgSnCode(needData[2])
                    setNowTime(needData[3].substring(0,7).replace(/-/g, '.'))
                    setShowSnImg(true)
                    setLastShowInformation(extendInformation)
                    increace()
                }else{
                    setIsModalOpen(true)
                }               
            }else{
                if(needData[0] === '0'){
                    setCodeImg(imageUrl);
                    setImgSnCode(needData[2])
                    setNowTime(needData[3].substring(0,7).replace(/-/g, '.'))
                    setShowSnImg(true)
                    setLastShowInformation(extendInformation)
                }else{
                    setIsModalOpen(true)
                }            
                setIsUseModalOpen(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    
    const getSNInformation = () =>{
        Axios.post(getApiUrlAnother() + "/parsesn",{sn:snCode}).then(res => {
            if(res.status === 200){
                if(res.headers['content-type'].split('/')[0] === '0'){
                    setShowInformation(true)
                    setPresentationInformation(res.data)

                }else{
                    setShowInformation(false)

                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    //刷新按钮
    const changeNewSn = () =>{
        setInputDisabled(true)
        Axios.post(getApiUrlAnother() + "/getsn", param).then((res) => {
            //这里得到最新的信息，除了序列号
            return res.data.sn;
            // setSnCode(res.data.sn)
        }).then((snLetter)=>{
            //这里得到了sn码，然后掉加一接口得到最新的序号
             Axios.post(getApiUrlAnother() + "/getnum",{sn:snLetter}).then(res => {
                //这里的res的data放进parm中，然后就生成码了
                const newData = {...param}
                
                if(res.status === 200){
                    if(res.headers['content-type'].split('/')[0] === '0'){
                        setSnNewSequence(res.data.series_num)
                        setAnother(res.data.series_num)
                        newData.today_series = res.data.series_num
                        userParam(newData)
                        Axios.post(getApiUrlAnother() + "/getsn", newData).then((res) => {
                            //这里得到最新的信息，除了序列号
                            setSnCode(res.data.sn)
                        })
                    }
                }
                return newData; 
            }).catch(err => {
                console.log(err)
            })
        })
        .catch(err=>{
            console.log(err)
        })
    }

    //得到当前序号
    const getNowSnSequence = async (snCode) => {
        let todayNum = await Axios.post(getApiUrlAnother() + "/getnum",{sn:snCode}).then(res => {
            if(res.status === 200){
                if(res.headers['content-type'].split('/')[0] === '0'){
                    setSnNewSequence(res.data.series_num)
                    setAnother(res.data.series_num)
                    return res.data.series_num
                }
            }
        }).catch(err => {
            console.log(err)
        })
        return todayNum;
    }

    const toBeEdit = () =>{
        setInputDisabled(false)
    }

    const print= () => {
        window.print()
    }
    const previewPrint = () =>{
        setIsImgModalOpen(true)
        }
    
    const firstShow = async () => {
        try {
            // 根据默认信息得到初始的sn
            const firstResponse = await Axios.post(getApiUrlAnother() + "/getsn", param).catch(err => {
                console.log(err)
            });
            const sn = firstResponse.data.sn;
    
            // 根据sn得到此数据最新的序号
            const secondResponse = await Axios.post(getApiUrlAnother() + "/getnum", { sn: sn }).catch(err => {
                console.log(err)
            });
            if (secondResponse.status === 200) {
                const seriesNum = secondResponse.data.series_num;
                setSnNewSequence(seriesNum);
                setAnother(seriesNum)
                // 根据最新的信息得到sn码以此来得到图片
                const thirdResponse = await Axios.post(getApiUrlAnother() + "/getsn", { pdt_series: 0, pa_type: [[-1, 0],[-1, 0],[-1, 0],[-1, 0],[-1, 0],[-1, 0],[-1, 0],[-1, 0]], lna_module: 0, base_board: 7, distance: 4, today_series: seriesNum} ).catch(err => {
                    console.log(err)
                });
                const snCode = thirdResponse.data.sn;
                setSnCode(snCode);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const increace = async () =>{
        //这儿根据sn信息来得到加1后的序列号
        let newNum = await getNowSnSequence(snCode);
        if(newNum > 34) {
            setIsNumberModalOpen(true)
            
            return ;
        }
        Axios.post(getApiUrlAnother() + "/getsn", param).then(async(res) => {
            return res.data.sn
        }).then((snLetter)=>{
            //这里得到了sn码，然后掉加一接口得到最新的序号
             Axios.post(getApiUrlAnother() + "/nextnum",{sn:snLetter}).then(res => {
                //这里的res的data放进parm中，然后就生成码了
                const newData = {...param}
                
                if(res.status === 200){
                    if(res.headers['content-type'].split('/')[0] === '0'){
                        setSnNewSequence(res.data.series_num)
                        setAnother(res.data.series_num)
                        newData.today_series = res.data.series_num
                        userParam(newData)
                    }
                }
                return newData; 
            }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            console.log(err)
        })
    }

    const changeNumber = (value) => {
        if(value > another){
            setSnNewSequence(another)
            setIsMaxModalOpen(true)
        }else{
            setSnNewSequence(value)
            const newData = {...param}
            newData.today_series = value
            Axios.post(getApiUrlAnother() + "/getsn", newData).then((res) => {
                //这里得到最新的信息，除了序列号
                setSnCode(res.data.sn)
            })
        }

    }

    useEffect(() => {
        setShowSnImg(false)
        firstShow()
    },[])
   
    return (
        <Layout>
            <Content
                className="site-layout"
                style={{
                    padding: '0 50px',
                }}
            >
                <div className='home generateEncoding non-printable'>
                    <Row >
                        <Col span={6}>产品系列:</Col>
                        <Col span={18}>
                            {SelectComponent(productOptions, param.pdt_series, (value) => { powerChangeSelect(value, 'pdt_series') }, true, 230)}
                        </Col>
                    </Row>
                    <Row >
                        <Col span={6}>433M PA:</Col>
                        <Col span={18}>{SelectPowerComponent((e) => { powerChangeSelect(e.target.value, "433L") }, param.pa_type[0][0], (value) => { powerChangeSelect(value, "433R") }, param.pa_type[0][1])}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>900M PA:</Col>
                        <Col span={18}>{SelectPowerComponent((e) => { powerChangeSelect(e.target.value, "900L") }, param.pa_type[1][0], (value) => { powerChangeSelect(value, "900R") },param.pa_type[1][1])}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>1.2G PA:</Col>
                        <Col span={18}>{SelectPowerComponent((e) => { powerChangeSelect(e.target.value, "1.2L") }, param.pa_type[7][0], (value) => { powerChangeSelect(value, "1.2R") },param.pa_type[7][1])}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>1.4G PA:</Col>
                        <Col span={18}>{SelectPowerComponent((e) => { powerChangeSelect(e.target.value, "1.4L") }, param.pa_type[2][0], (value) => { powerChangeSelect(value, "1.4R") },param.pa_type[2][1])}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>1.5G PA:</Col>
                        <Col span={18}>{SelectPowerComponent((e) => { powerChangeSelect(e.target.value, "1.5L") }, param.pa_type[3][0], (value) => { powerChangeSelect(value, "1.5R") },param.pa_type[3][1])}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>1.8G PA:</Col>
                        <Col span={18}>{SelectPowerComponent((e) => { powerChangeSelect(e.target.value, "1.8L") }, param.pa_type[4][0], (value) => { powerChangeSelect(value, "1.8R") },param.pa_type[4][1])}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>2.4G PA:</Col>
                        <Col span={18}>{SelectPowerComponent((e) => { powerChangeSelect(e.target.value, "2.4L") }, param.pa_type[5][0], (value) => { powerChangeSelect(value, "2.4R") },param.pa_type[5][1])}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>5.8G PA:</Col>
                        <Col span={18}>{SelectPowerComponent((e) => { powerChangeSelect(e.target.value, "5.8L") }, param.pa_type[6][0], (value) => { powerChangeSelect(value, "5.8R") },param.pa_type[6][1])}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>LNA模组:</Col>
                        <Col span={18}>{RadioComponent(LNAOptions, (e) => { powerChangeSelect(e.target.value, "lna_module") }, param.lna_module)}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>载板模型:</Col>
                        <Col span={18}>{RadioComponent(carrierPlateTypesOptions, (e) => { powerChangeSelect(e.target.value, "base_board") }, param.base_board)}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>距离:</Col>
                        <Col span={18}>{RadioComponent(distanceOptions, (e) => { powerChangeSelect(e.target.value, "distance") }, param.distance)}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>当日型号序号:</Col>
                        <Col span={18}>{NumberComponent(snNewSequence,toBeEdit,inputDisabled,(e)=>changeNumber(e),snNewSequence)}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>扩展信息:</Col>
                        <Col span={18}><Input placeholder="扩展信息"  style={{width:'300px'}} onChange={(e) => setExtendInformation(e.target.value)} value={extendInformation} /></Col>
                    </Row>
                </div>
                <Modal key={new Date()} style={{position:'relative',top:'30%',}}  title={showInformation ? `序列号<${snCode}>信息` : '错误'} className='snInformation non-printable' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[<Button key="back" onClick={handleCancel}>确定</Button>]} > 
                    {
                        showInformation ? (
                            <div>
                            <p><span>产品类型(product_type):</span>{presentationInformation.pdt_series}</p>
                            {presentationInformation.pa_type_433 && (<p><span>433M功放类型(433M_PA):</span>{presentationInformation.pa_type_433}</p>)}
                            {presentationInformation.pa_type_900 && (<p><span>900M功放类型(900M_PA):</span>{presentationInformation.pa_type_900}</p>)}
                            {presentationInformation.pa_type_1200 && (<p><span>1.2G功放类型(1.2G_PA):</span>{presentationInformation.pa_type_1200}</p>)}
                            {presentationInformation.pa_type_1400 && (<p><span>1.4G功放类型(1.4G_PA):</span>{presentationInformation.pa_type_1400}</p>)}
                            {presentationInformation.pa_type_1500 && (<p><span>1.5G功放类型(1.5G_PA):</span>{presentationInformation.pa_type_1500}</p>)}
                            {presentationInformation.pa_type_1800 && (<p><span>1.8G功放类型(1.8G_PA):</span>{presentationInformation.pa_type_1800}</p>)}
                            {presentationInformation.pa_type_2400 && (<p><span>2.4G功放类型(2.4G_PA):</span>{presentationInformation.pa_type_2400}</p>)}
                            {presentationInformation.pa_type_5800 && (<p><span>5.8G功放类型(5.8G_PA):</span>{presentationInformation.pa_type_5800}</p>)}
                            {presentationInformation.rf_module !== '无' && (<p><span>射频模块(RF_module):</span>{presentationInformation.rf_module}</p>)}
                            <p><span>载板类型(base_board):</span>{presentationInformation.base_board}</p>
                            <p><span>覆盖范围(range):</span>{presentationInformation.range}</p>
                            <p><span>生产日期:</span>{presentationInformation.pdt_date}</p>
                            <p><span>当日序号:</span>{presentationInformation.today_series}</p>
                            </div>
                        ) : (
                            <div style={{display:'flex',alignItems:'center'}}><span className='error'></span>列号SN检验失败</div>
                        )
                    }
                    
                    
                </Modal>
                <Modal open={isNumberModalOpen} closeIcon={null} style={{position:'relative',top:'30%',}}  onCancel={handleImgCancel} footer={[<Button key="back" onClick={handleNumberOk}>确定</Button>]}>
                <div style={{display:'flex',alignItems:'center'}}><span className='warning'></span>此系列设备数量已经满足36台</div>
                </Modal>
                <Modal open={isMaxModalOpen} closeIcon={null} style={{position:'relative',top:'30%',}}  onCancel={handleImgCancel} footer={[<Button key="back" onClick={handleMaxOk}>确定</Button>]}>
                <div style={{display:'flex',alignItems:'center'}}><span className='warning'></span>此序号已超过当前序号</div>
                </Modal>
                <Modal open={isUsedModalOpen} closeIcon={null} style={{position:'relative',top:'30%',}}  onCancel={handleImgCancel} footer={[<Button key="back" onClick={handleUsedOk}>确定</Button>]}>
                <div style={{display:'flex',alignItems:'center'}}><span className='warning'></span>此sn码已经被使用,请点击刷新</div>
                </Modal>
                <Modal open={isImgModalOpen} closeIcon={null} style={{position:'relative',top:'30%',}} onOk={handleImgOk} onCancel={handleImgCancel} footer={[<Button key="back" onClick={handleImgOk}>确定</Button>]}>
                <div  className='codeChild ' style={{margin:'auto',textAlign:'center'}}>
                {
                       showSnImg && <>
                        <img src={codeImg} alt="" style={{width:'320px',height:'70px',margin:'auto'}}  />
                    </>
                }
                </div>
                </Modal>
            </Content>
            <Footer>
                <Row  wrap={true} style={{marginBottom:'15px'}} className='non-printable'>
                    <Col span={16} style={{display:'flex', flexDirection:'column'}} >
                        <div style={{display:'flex', justifyContent:'space-around',marginBottom:'10px' ,width:'320px'}} className='SNClass'>
                            <Button onClick={changeTobeDefault}>重置</Button>
                            <Button onClick={changeNewSn}>刷新</Button>
                            <Button onClick={viewDetails}>解析</Button>
                        </div>
                        <Card style={{border:'1px solid black', width:'320px'}} className='SNShow'>
                            <div style={{ display: 'flex',flexDirection:'column', alignItems: 'center', justifyContent: 'center' }} >
                                <span style={{fontSize:'16px',fontWeight:'900',marginRight:'5px'}}>
                                    序列号SN
                                </span>
                                <span>
                                 <Input value={snCode} style={{fontSize:'22px',fontWeight:'900'}} onChange={(e) => setSnCode(e.target.value)}></Input>
                                </span>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row  wrap={true} >
                    <Col span={18} style={{display:'flex',flexDirection:'column'}} >
                    <div style={{display:'flex', justifyContent:'space-around',marginBottom:'10px', width:'320px'}} className='CodeClass non-printable'>
                            <Button onClick={generateCode}>生成</Button>
                            <Button onClick={previewPrint}>预览打印</Button>
                            <Button onClick={print}>打印</Button>
                        </div>
                        <Card style={{border:'1px solid black', width:'320px'}} className='codeImgShow print-only' >
                            <div  className='codeChild '>
                                {
                                    showSnImg && <>
                                <img src={codeImg} alt="" className='codeImg' />
                                    </>
                                }
                            
                            </div>
                        </Card>
                        <img src={codeImg} alt="" className='printImg'  />
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
};
export default memo(GenerateEncoding);

const SelectComponent = (selectOption, value, handleChange, disabled, widthNumber = 120 ) => (
    <Space wrap>
        <Select
            style={{
                width: widthNumber,
            }}
            disabled={!disabled}
            onChange={handleChange}
            options={selectOption}
            defaultValue={selectOption[0]}
            value={value}
        />
    </Space>
);

const RadioComponent = (options, onChange, value) => {
    return (
        <Radio.Group onChange={onChange} value={value}>
            {options.map(item => {
                return <Radio key={item.label} value={item.value}>{item.label}</Radio>
            })}
        </Radio.Group>
    );
};

const SelectPowerComponent = (onChange, value, handleChange,selectValue, selectOption=directionOptions, options=powerOptions, ) => {
    return (
        <div >
           <span style={{marginRight:20,fontWeight:'800'}}>功率:</span>
           <Radio.Group onChange={onChange} value={value}>
            {options.map(item => {
                return <Radio key={item.label} value={item.value}>{item.label}</Radio>
            })}
        </Radio.Group> 
        <span style={{marginRight: 10,fontWeight: '800'}}>辐射方向:</span>
        <Select
            style={{
                width: 120,
            }}
            onChange={handleChange}
            options={selectOption}
            defaultValue={selectValue}
            value={selectValue}
        />
        </div>
    )
}

const  NumberComponent = (value,onChange,inputDisabled,changeNumber) => (
    <div style={{display:'flex',alignItems:'center'}}>
        <InputNumber   value={value } disabled={inputDisabled} onChange={changeNumber}  controls={false}  changeOnBlur={true} style={{width:'60px',color:'black'}}/>
        <Button style={{width:'75px',height:'30px',marginLeft:'10px',borderRadius:'6px',border:'1px solid #ccc',cursor:'pointer',background:'white'}}  onClick={onChange}>编辑</Button>
    </div>
    
)
