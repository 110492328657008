import React from 'react';
import { Layout, Row, Col, Checkbox, Button, Switch, Radio, Divider, Descriptions, Input } from 'antd';
import { Select, Space } from 'antd';
import './queryModal.css'
import { defenceDistance, defendBandOptions, descFormat, detectBandOptions, detectDistance, directBandOptions, directDistance, shapeOptions, spoofBandOptions, spoofDistance } from './utils';
import { useState } from 'react';
import Item from 'antd/es/list/Item';
import Axios from 'axios';
import { getApiUrl } from '../..//Httputil/httpsutil';
import axios from 'axios';

const { Content, Footer } = Layout;
const QueryModal = () => {

    const [param, userParam] = useState({ shape: "", detect_flag: false, attack_flag: false, direct_flag: false, spoof_flag: false, distance: [0, 0, 0, 0], ante_count: [0, 0, 0, 0], detect_bands: [], jamming_bands: [], direct_bands: [], spoof_bands: [],custom:"", })
    const [result, setResult] = useState("")
    const [descriptions, setDescpritions] = useState([])
    const onChangeCheck = (checkedValues, type) => {
        let temp = { ...param }
        temp[type] = checkedValues
        userParam(temp)
    };
    const onChangeSelect = (checkedValues, type) => {
        let newData = { ...param }
        switch (type) {
            case 0:
                newData.distance[0] = checkedValues
                break
            case 1:
                newData.distance[1] = checkedValues
                break
            case 2:
                newData.distance[2] = checkedValues
                break
            case 3:
                newData.distance[3] = checkedValues
                break
        }
        userParam(newData)
        // console.log('select data = ', newData);
    };
    const onChangeSwitch = (checked, type) => {
        let temp = { ...param }
        temp[type] = checked
        userParam(temp)
        // console.log("switch", temp)
    }

    const onChangeInput = (value,type) => {
        let temp = {...param }
        temp[type] = value
        // console.log("input change",type,value,temp)
        userParam(temp)
    }




    const onSearch = (value, _e, info) => {
        axios.post(getApiUrl() + "/search", { "pid": value }).then(res => {
            let desctiptionItems = []
            // Object.keys(res.data).map(item => {
            //     desctiptionItems.push({
            //         "key": item,
            //         "label": getInfoKeyName(item),
            //         "children": getDescVal(res.data[item])
            //     })
            // })
            // console.log("Res",res.data)
            setDescpritions(descFormat(res.data))
        }).catch(e=>{
            console.log("get pid info error",e)
        })

    }

    const handleOk = () => {
        let res = { ...param }
        res.ante_count = [res.detect_bands.length, res.jamming_bands.length, res.direct_bands.length, res.spoof_bands.length]
        res.detect_bands = res.detect_bands ? res.detect_bands : null
        res.jamming_bands = res.jamming_bands ? res.jamming_bands : null
        res.direct_bands = res.direct_bands ? res.direct_bands : null
        res.spoof_bands = res.spoof_bands ? res.spoof_bands : null
        if (typeof(res.custom) == "boolean"){
            res.custom = ""
        }
        Axios.post(getApiUrl() + "/gen", res).then(res => {
            setResult(res.data)
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <Layout>
            <Content
                className="site-layout"
                style={{
                    padding: '0 50px',
                }}
            >
                <Item
                    style={{
                        margin: '16px 0',
                    }}
                >
                    产品参数选择
                    {/* <Breadcrumb.Item>List</Breadcrumb.Item>
                    <Breadcrumb.Item>App</Breadcrumb.Item> */}
                </Item>
                {SearchCompent(onSearch)}
                {DescriptionComponent(descriptions)}
                <Divider></Divider>
                <div className='home'>
                    <Row >
                        <Col span={6}>外形样式:</Col>
                        <Col span={18}>
                            {RadioComponent(shapeOptions, (e) => { onChangeCheck(e.target.value, "shape") }, param.shape)}
                        </Col>
                    </Row>
                    <Row >
                        <Col span={6}>是否定制:</Col>
                        <Col span={4}>
                            {<Switch onChange={(value) => { onChangeSwitch(value, "custom") }}></Switch>}
                        </Col>
                        <Col span={10}>
                            {param.custom && <Input placeholder='请输入定制名称' onChange={(e)=>{onChangeInput(e.target.value,"custom")}} ></Input>}
                        </Col>
                    </Row>
                    <Row >
                        <Col span={6}>侦测支持:</Col>
                        <Col span={18}>{<Switch onChange={(value) => { onChangeSwitch(value, "detect_flag") }}></Switch>}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>侦测距离:</Col>
                        <Col span={18}>{SelectComponent(detectDistance, (value) => { onChangeSelect(value, 0) }, param.detect_flag)}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>侦测频段:</Col>
                        <Col span={18}>{CheckboxComponent(detectBandOptions, [], (value) => { onChangeCheck(value, "detect_bands") }, param.detect_flag)}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>打击支持:</Col>
                        <Col span={18}>{<Switch onChange={(value) => { onChangeSwitch(value, "attack_flag") }}></Switch>}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>干扰距离:</Col>
                        <Col span={18}>{SelectComponent(defenceDistance, (value) => { onChangeSelect(value, 1) }, param.attack_flag)}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>干扰频段:</Col>
                        <Col span={18}>{CheckboxComponent(defendBandOptions, [], (value) => { onChangeCheck(value, "jamming_bands") }, param.attack_flag)}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>定向支持:</Col>
                        <Col span={18}>{<Switch onChange={(value) => { onChangeSwitch(value, "direct_flag") }}></Switch>}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>定向距离:</Col>
                        <Col span={18}>{SelectComponent(directDistance, (value) => { onChangeSelect(value, 2) }, param.direct_flag)}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>定向频段:</Col>
                        <Col span={18}>{CheckboxComponent(directBandOptions, [], (value) => { onChangeCheck(value, "direct_bands") }, param.direct_flag)}</Col>
                    </Row>
                    <Row >
                        <Col span={6}>诱骗支持:</Col>
                        <Col span={18}>{<Switch onChange={(value) => { onChangeSwitch(value, "spoof_flag") }}></Switch>}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>诱骗距离:</Col>
                        <Col span={18}>{SelectComponent(spoofDistance, (value) => { onChangeSelect(value, 3) }, param.spoof_flag)}</Col>
                    </Row>
                    <Row>
                        <Col span={6}>诱骗频段:</Col>
                        <Col span={18}>{CheckboxComponent(spoofBandOptions, [], (value) => { onChangeCheck(value, "spoof_bands") }, param.spoof_flag)}</Col>
                    </Row>
                </div>
            </Content>
            <Footer>
                <Row  wrap={true}>
                    <Col  style={{"display":"flex","alignItems":"center"}} span={8}><Button onClick={handleOk}>型号生成</Button></Col>
                    <Col span={16} > <h4>{result}</h4></Col>
                </Row>
            </Footer>
        </Layout>
    );
};
export default QueryModal;


const getDescVal = (val) => {
    if (typeof (val) === "boolean" && val) {
        return "是"
    } else if (typeof (val) === "boolean" && !val) {
        return "否"
    } else if (val instanceof Array) {
        return val.join("/")
    } else if (val instanceof Array && val.length == 0) {
        return "无"
    } else {
        return val
    }
}


const CheckboxComponent = (options, defaultVal, onChange, disableed) => (
    <>
        <Checkbox.Group
            options={options}
            defaultValue={defaultVal}
            onChange={onChange}
            disabled={!disableed}
        />
    </>
)

const SelectComponent = (selectOption, handleChange, disabled) => (
    <Space wrap>
        <Select
            style={{
                width: 120,
            }}
            disabled={!disabled}
            onChange={handleChange}
            options={selectOption}
        />
    </Space>
);


const RadioComponent = (options, onChange, value) => {
    return (
        <Radio.Group onChange={onChange} value={value}>
            {options.map(item => {
                return <Radio key={item.label} value={item.value}>{item.label}</Radio>
            })}
        </Radio.Group>
    );
};


const { Search } = Input;

const SearchCompent = (onSearch) => (
    <Space direction="vertical">
        <Search
            placeholder="输入pid 查询"
            onSearch={onSearch}
            style={{
                width: "80vw",
            }}
        />
    </Space>)


const DescriptionComponent = (items) => (<Descriptions  column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2}} items={items} />)