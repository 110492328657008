import "axios"



export const getApiUrl = () => {
    return process.env.NODE_ENV === "development" ? "/api/v1" : window.location.origin + "/v1"
}
export const getApiUrlAnother = () => {
    return process.env.NODE_ENV === "development" ? "/codeApi/v1" : window.location.origin + "/v1"
}

