
export const shapeOptions = [
    {
        label: '机箱式',
        value: '机箱式',
    },
    {
        label: '一体机',
        value: '一体机',
    },
    {
        label: '察打枪',
        value: '察打枪',
    },
    {
        label: '便携箱',
        value: '便携箱',
    },
    {
        label: '手持式',
        value: '手持式',
    },
    {
        label: '定制机箱',
        value: '定制机箱',
    },
    {
        label: '光电设备',
        value: '光电设备',
    }
];

export const detectBandOptions = [
    {
        label: '4G通讯',
        value: '4G通讯',
    },
    {
        label: 'WiFi侦测',
        value: 'WiFi',
    },
    {
        label: '全频',
        value: '全频',
    },
    {
        label: '433M',
        value: '433M',
    },
    {
        label: '800M',
        value: '800M',
    },
    {
        label: '900M',
        value: '900M',
    },
    {
        label: '1.4G',
        value: '1.4G',
    },
    {
        label: '2.4G',
        value: '2.4G',
    },
    {
        label: '5.2G',
        value: '5.2G',
    },
    {
        label: '5.8G',
        value: '5.8G',
    },
];

export const defendBandOptions = [
    {
        label: '433M',
        value: '433M',
    },

    {
        label: '800M',
        value: '800M',
    },
    {
        label: '900M',
        value: '900M',
    },
    {
        label: '1.2G',
        value: '1.2G',
    },
    {
        label: '1.4G',
        value: '1.4G',
    },
    {
        label: '1.5G',
        value: '1.5G',
    },
    {
        label: '1.8G',
        value: '1.8G',
    },
    {
        label: '2.3G',
        value: '2.3G',
    },
    {
        label: '2.4G',
        value: '2.4G',
    },
    {
        label: '2.5G',
        value: '2.5G',
    },
    {
        label: '5.2G',
        value: '5.2G',
    },
    {
        label: '5.8G',
        value: '5.8G',
    },
];


export const directBandOptions = [
    {
        label: '2.4G',
        value: '2.4G',
    },
    {
        label: '5.8G',
        value: '5.8G',
    },
];

export const spoofBandOptions = [
    {
        label: 'L1',
        value: 'L1',
    },
    {
        label: 'L2',
        value: 'L2',
    },
];

export const detectDistance = [
    {
        value: 0.5,
        label: '500M',
    },
    {
        value: 1,
        label: '1KM',
    },
    {
        value: 3,
        label: '3KM',
    },
    {
        value: 5,
        label: '5KM',
    },
    {
        value: 8,
        label: '8KM',
    },
]

export const defenceDistance = [
    {
        value: 1,
        label: '1KM',
    },
    {
        value: 2,
        label: '2KM',
    },
    {
        value: 3,
        label: '3KM',
    },
]

export const directDistance = [
    {
        value: 1,
        label: '1KM',
    },
    {
        value: 2,
        label: '2KM',
    },
    {
        value: 3,
        label: '3KM',
    },
]

export const spoofDistance = [
    {
        value: 1,
        label: '1KM',
    },
    {
        value: 5,
        label: '5KM',
    },
    {
        value: 10,
        label: '10KM',
    },
]


export const getInfoKeyName = (key) => {
    const infoMap = {
        "detect": "侦测参数",
        "jamming": "打击参数",
        "direct": "定向参数",
        "spoof": "诱骗参数",
        "shape": "设备外形",
        "jamming_flag":"打击支持",
        "detect_flag": "侦测支持",
        "attack_flag": "打击支持",
        "direct_flag": "定向支持",
        "spoof_flag": "诱骗支持",
        "distance": "距离",
        "ante_count": "数量统计",
        "detect_bands": "检测频段",
        "jamming_bands": "打击频段",
        "direct_bands": "定向频段",
        "spoof_bands": "诱骗频段",
        "legacy": "历史\xa0\xa0\xa0\xa0\xa0\xa0\xa0",
        "custom": "定制化\xa0\xa0\xa0\xa0"
    }
    return infoMap[key]
}

const transformTypeToSeq = (type) => {
    switch (type) {
        case "detect":
            return 0
        case "jamming":
            return 1
        case "direct":
            return 2
        case "spoof":
            return 3
        default:
            break;
    }
}

const getChildren = (type, data) => {
    const excludelList = ["detect", "jamming", "direct", "spoof"]
    if (!excludelList.includes(type)) {
        if (typeof(data[type]) == "boolean"){
            return data[type] ? "是" : "否"
        }else return data[type]
    } else {
        return (
            <>
                {getInfoKeyName(type=="jamming" ? "attack_flag" : type + "_flag")}: {data[type=="jamming" ? "attack_flag" : type + "_flag"] ? "是" : "否"}
                <br />
                {getInfoKeyName("distance")}: {data["distance"][transformTypeToSeq(type)]}
                <br />
                {getInfoKeyName(type + "_bands")}: {data[type + "_bands"].join("/")}
                <br />
                频段数量: {data["ante_count"][transformTypeToSeq(type)]}
                <br />
            </>)
    }


}

// getNameType = () => {

// }


export const descFormat = (data) => {
    let res = []
    let listArray = ["shape", "detect", "jamming", "direct", "spoof", "legacy", "custom"]
    listArray.map(item => {
        let temp = {
            key: item,
            span:  item == "shape" ? {xl: 2, xxl: 2} : {xl: 1, xxl: 1},
            label: getInfoKeyName(item),
            children: getChildren(item, data),
        }
        res.push(temp)
    })
    return res

}